.container {
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.pageTitle {
  font-size: 5rem;
  text-align: center;
  margin-top: 3vh;
  color: #E89E84;
  font-family: "Yeseva One, serif" !important;
  font-weight: bold;
}

.pageSubtitle {
  font-size: 2rem;
  text-align: center;
  max-width: 27vw;
  margin-bottom: 20px;
  color: white;
  font-family: "Poppins", Helvetica;
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.pageContent {
  margin-top: 3vh;
  font-size: 1rem;
  line-height: 1.5;
  text-align: justify;
  margin-bottom: 30px;
  color: white;
}

.description {
  font-family: "Poppins", Helvetica;
  max-width: 28vw;
}

.flexContainer {
  display: flex;
  flex-direction: row; /* Default direction: row for larger screens */
}
