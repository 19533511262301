.modal-body {
  background-color: #ddc7bf;
  color: #4b4b4a;
}

.modal-header {
  background-color: #ddc7bf;
  color: #4b4b4a;
}

.modal-field {
  margin-bottom: 10px;
}

.submit-form-button {
  background-color: #4b4b4a;
  color: #ddc7bf;
  font-size: 1rem;
  border-radius: 5px;
  border-color: #616161;
  margin-right: 10px;
}

.submit-form-button:hover {
  color: #4b4b4a;
  background-color: #ddc7bf;
  border-color: #4b4b4a;
}

.submit-form-button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.nav-link {
  justify-content: center;
  color: #4b4b4a !important;
  font-size: 1.1rem;
  font-weight: bold;
}

.textbox {
  background-color: #c5afa7;
  border: 1px solid #c5afa7;
}

.textbox:focus {
  background-color: #c5afa7;
  border-color: #c5afa7;
  outline: none;
}

.custom-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.subtab {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.tab-title {
  width: 100%;
  text-align: center;
}

.google-sign-in-button:hover {
  color: #4b4b4a !important;
  background-color: #ddc7bf !important;
  border-color: #4b4b4a !important;
}

.error-box {
  display: inline-flex;
  border: 1px solid #ff0000;
  background-color: #ffecec;
  padding: 5px;
  border-radius: 5px;
  margin-left: 8px;
}

.text-danger {
  margin: 0;
  color: #ff0000;
}

.success-box {
  display: inline-flex;
  border: 1px solid #00ff1a;
  background-color: #e6ffe6;
  padding: 5px;
  border-radius: 5px;
  margin-left: 8px;
}

.text-success {
  margin: 0;
  color: #00ff1a;
}
