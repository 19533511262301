.modal-body {
  background-color: #ddc7bf;
  color: #4b4b4a;
}

.modal-header {
  background-color: #ddc7bf;
  color: #4b4b4a;
}

.modal-field {
  padding: 1vh;
}

.submit-form-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 651px) {
  @media only screen and (max-width: 651px) {
    .submit-form-button {
      height: 5vh;
      width: 25vw;
      display: inline;
      padding: 0;
    }
  }

  @media only screen and (min-width: 651px) {
    .submit-form-button {
      height: 50px;
      width: 140px;
      margin-right: 30vw;
      padding: 0;
    }
  }
}

@media only screen and (min-width: 651px) {
  .submit-form-button {
    height: 50px;
    width: 140px;
    margin-right: 30vw;
    padding: 0;
  }
}

.submit-form-button {
  background-color: #4b4b4a;
  color: #ddc7bf;
  font-size: 1.2rem;
  border-radius: 10px;
}

.submit-form-button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
