.modal-body {
  background-color: #ddc7bf;
  color: #4b4b4a;
}

.modal-header {
  background-color: #ddc7bf;
  color: #4b4b4a;
}

.modal-field {
  margin-bottom: 10px;
}

.submit-form-button {
  background-color: #4b4b4a;
  color: #ddc7bf;
  font-size: 1rem;
  border-radius: 5px;
  border-color: #616161;
  margin-right: 10px;
}

.submit-form-button:hover {
  color: #4b4b4a;
  background-color: #ddc7bf;
  border-color: #4b4b4a;
}

.submit-form-button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.textbox {
  background-color: #c5afa7;
  border: 1px solid #c5afa7;
}

.textbox:focus {
  background-color: #c5afa7;
  border-color: #c5afa7;
  outline: none;
}
